// Load Bootstrap JS
import bootstrap from 'bootstrap';
import aos from 'aos';
import '../scss/main.scss';

aos.init({
    offset:0
});

// App code
var hamburgerOpen = false;
const Hamburger = document.querySelector('#hamburger');
const OffcanvasMenu = document.querySelector('#offcanvasMenu');

OffcanvasMenu.addEventListener('hide.bs.offcanvas', function () {
    Hamburger.classList.remove('is-active');
    hamburgerOpen = false;
});
OffcanvasMenu.addEventListener('show.bs.offcanvas', function () {
    Hamburger.classList.add('is-active');
    hamburgerOpen = true;
});

const Navbar = document.querySelector('#navbar');
const NavbarImage = document.querySelector('#navbarImage');
let NavbarImageHide = NavbarImage.getAttribute('data-navbar-image-hide');
if(NavbarImageHide == "true") NavbarImage.classList.add('d-none');

window.addEventListener('scroll', function() {
    var _y = window.pageYOffset;   
    if(Navbar){
        if(_y > 100) { 
            Navbar.classList.add('bg-light');
            NavbarImage.classList.remove('d-none');
        } else {
            Navbar.classList.remove('bg-light');
            if(NavbarImageHide == "true") NavbarImage.classList.add('d-none');
        }
    }
}, {passive: true});
